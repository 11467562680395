<template>
    <v-card>
		<v-card-text class="h-100">
			<v-row no-gutters class="h-100">
				<v-col :cols="12">
					<v-row no-gutters class="h-100">
						<v-col :cols="12">
							<h3 class="fs-16 lh-22 fw-700 black--text">
								{{ model.job_name }}
							</h3>
							<p class="subtitle"><span class="grey--text">{{ model.job_subname }}</span></p>
						</v-col>
					</v-row>
				</v-col>
				
				<v-col :cols="12">
					
					<v-divider class="mt-9 mb-4" />
					
					<v-row no-gutters>
						<v-col cols="12">
							<span class="grey--text">{{ tasksCountFormatted }}</span>
						</v-col>
						<v-col cols="12">
							<h2 class="d-flex flex-row flex-nowrap justify-start align-center">
								<v-icon class="mr-1">mdi-clock-time-four-outline</v-icon>
								<span>{{ model.time_formatted }}</span>
							</h2>
						</v-col>
					</v-row>

					<v-row no-gutters>
						<v-col cols="12" class="d-flex flex-row flex-nowrap justify-start align-center">
							<v-btn 
								@click.prevent.stop="start"
								:elevation="0" 
								color="green darken-0"
								class="w-flex-100 mt-4"
							>
								Начать работу
							</v-btn>
						</v-col>
					</v-row>
				</v-col>
			</v-row>
		</v-card-text>
	</v-card>
</template>
<script>
const plural = require('plural-ru');
export default {
    name: 'AssignedJobCard',
    props: {
    	model: Object,
    	detailUrl: String
    },
    computed: {
    	viewUrl() {
    		return this.detailUrl
				.replace('{id}', _.get(this, 'model.id', 0))
				.replace('{subject}', _.get(this, 'model.subject', '').replace(' ', '-'))
				.replace('{grade}', _.get(this, 'model.grade', 0))
				.replace('{is_final}', _.get(this, 'model.is_final', 0));
    	},
		tasksCountFormatted() {
			return plural(this.model.job_tasks_count, '%d вопрос', '%d вопроса', '%d вопросов');
		}
    },
	methods: {
		start() {
			return this.$router.push(this.viewUrl).catch(() => {});
			// return this.$emit('start', 1);
		}
	}
}
</script>
<style lang="scss" scoped>
	h3{
		min-height: 2.75rem;
	}
	.subtitle{
		min-height: 3rem;
	}
</style>