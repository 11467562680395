<template>
    <div>
        <nav class="w-100">
            <span class="container--landing d-flex flex-row flex-nowrap justify-center align-items-center">
                <a href="#about" class="fs-18 lh-21 fw-500">Что такое ВПР</a>
                <a href="#demo" class="fs-18 lh-21 fw-500">Пройти ВПР</a>
                <a href="#contacts" class="fs-18 lh-21 fw-500">Контакты</a>
                <a href="#help" class="fs-18 lh-21 fw-500">Помощь</a>
            </span>
        </nav>
        <article class="pt-12">
            <section id="head">
                <div class="container--landing pb-6">
                    <h1 class="fs-31 lh-39 fw-700 mb-6">СдамВПР</h1>
                    <h2 class="fs-22 lh-27 fw-500 mb-6">Мы поможем Вам подготовить Ваших учеников <br />к Всероссийской проверочной работе!</h2>
                    <img src="@/assets/img/landing/pic0.png" />
                </div>
            </section>

            <section id="about-section">
                <a class="nav-anchor" id="about"></a>
                <div class="container--landing pb-6">
                    <p>Решение предназначено в первую очередь для использования в работе учителей в школах, для подготовке к ВПР, а так же для среза знаний в классе или параллели и позволит вам:</p>

                    <v-list flat color="transparent">
                        <v-list-item>
                            <v-list-item-icon>
                                <v-avatar :size="54" color="grey darken-4">
                                    <img class="list-icon" src="@/assets/img/landing/ico-portfolio.png" />
                                </v-avatar>
                            </v-list-item-icon>
                            <v-list-item-content class="fs-16 lh-22 fw-500">
                                Раздавать своим ученикам готовые варианты Всероссийских проверочных работ разработанных ФИОКО
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item>
                            <v-list-item-icon>
                                <v-avatar :size="54" color="grey darken-4">
                                    <img class="list-icon" src="@/assets/img/landing/ico-edit.png" />
                                </v-avatar>
                            </v-list-item-icon>
                            <v-list-item-content class="fs-16 lh-22 fw-500">
                                Сформировать работы из заданий разработанных ФИОКО
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item>
                            <v-list-item-icon>
                                <v-avatar :size="54" color="grey darken-4">
                                    <img class="list-icon" src="@/assets/img/landing/ico-tests.png" />
                                </v-avatar>
                            </v-list-item-icon>
                            <v-list-item-content class="fs-16 lh-22 fw-500">
                                Оценить работу каждого ученика и сравнить результаты всех учеников класса
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </div>
            </section>

            <section id="demo-section" class="grey darken-0">
                <a class="nav-anchor" id="demo"></a>
                <div class="container--landing pt-10 pb-15">
                    <div class="pb-6">Проверьте себя, пройдите тестовую ВПР на выбор</div>
                    
                    <v-row v-if="!isDemoFetch">
                        <v-col v-for="n in 3" :key="n" :lg="4">
                            <v-skeleton-loader
                                :elevation="2"
                                type="article, actions"
                            ></v-skeleton-loader>
                        </v-col>
                    </v-row>

                    <div v-else>
                        <v-carousel v-model="demo_carousel" height="auto" show-arrows-on-hover hide-delimiters :light="true">
                            <v-carousel-item
                                v-for="(demo_chunk, i) in demoChunked"
                                :key="i"
                            >
                                <div class="d-flex flex-row flex-nowrap justify-space-between align-stretch">
                                    <assigned-job-demo-card 
                                        v-for="(item, j) in demo_chunk" 
                                        :key="j" 
                                        :model="item"
                                        detail-url="/demo/{subject}/{grade}/{is_final}"
                                        class="assigned-job-demo-card mx-1"
                                        @start="showDev"
                                    />
                                </div>
                            </v-carousel-item>
                        </v-carousel>
                    </div>
                </div>
            </section>

            <section id="contacts-section">
                <a class="nav-anchor" id="contacts"></a>
                <div class="container--landing pt-10 pb-15">
                    <h2 class="fs-22 lh-27 fw-500 black--text mb-4">Контакты</h2>

                    <p class="px-4">По вопросам приобретения лицензий: <a href="mailto:sales@okovprkim.ru">sales@okovprkim.ru</a>.</p>
                    <p class="px-4">Техническая поддержка: <a href="mailto:support@okovprkim.ru">support@okovprkim.ru</a></p>
                    <p class="px-4">Методическая поддержка: <a href="mailto:metodist@okovprkim.ru">metodist@okovprkim.ru</a></p>
                </div>
            </section>
            <section id="help-section">
                <a class="nav-anchor" id="help"></a>
                <div class="container--landing pt-4 pb-10">
                    <h2 class="fs-22 lh-27 fw-500 black--text mb-4">Помощь</h2>
                    
                    <div>
                        <p class="fw-700">Как зарегистрировать в “СдамВПР”?</p>
                        <p>Чтобы зарегистрироваться необходимо преобрести лицензию для этого обратитесь по адресу <a href="mailto:sales@okovprkim.ru">sales@okovprkim.ru</a> и следовать инструкции прилагаемой к лицензии.
                        Если у Вас возникли какие-либо еще проблемы во время регистрации обратитесь в техническую поддержку по адресу <a href="mailto:support@okovprkim.ru">support@okovprkim.ru</a></p>
                        <v-divider class="my-5" />
                    </div>
                    <div>
                        <p class="fw-700">Можно ли купить лицензию только на один предмет?</p>
                        <p>Да, это возможно, по вопросам приобретения пишите на почту <a href="mailto:sales@okovprkim.ru">sales@okovprkim.ru</a></p>   
                        <v-divider class="my-5" /> 
                    </div>
                    <div>
                        <p class="fw-700">Можно ли купить лицензию только на все предметы для одной парралели?</p>
                        <p>Да, это возможно, по вопросам приобретения пишите на почту <a href="mailto:sales@okovprkim.ru">sales@okovprkim.ru</a></p>
                        <v-divider class="my-5" />
                    </div>
                    <div>
                        <p class="fw-700">Как приобрести лицензию на всю школу?</p>
                        <p>Да, это возможно, по вопросам приобретения пишите на почту <a href="mailto:sales@okovprkim.ru">sales@okovprkim.ru</a></p>
                    </div>
                </div>
            </section>
            <section id="download" class="grey darken-0">
                <div class="container--landing d-flex flex-row flex-wrap justify-space-between align-center py-9">
                    <a href="#" class="d-flex flex-row flex-nowrap justify-start align-center" @click.prevent.stop="showDev">
                        <img src="@/assets/img/landing/ico-appstore.png" class="mr-3" />
                        <span>
                            <span class="fs-10 lh-22 fw-500">Загрузите в</span><br /><span class="fs-14 lh-16 fw-500">App Store</span>
                        </span>
                    </a>
                    <a href="#" class="d-flex flex-row flex-nowrap justify-start align-center" @click.prevent.stop="showDev">
                        <img src="@/assets/img/landing/ico-gplay.png" class="mr-3" />
                        <span>
                            <span class="fs-10 lh-22 fw-500">Доступно на</span><br /><span class="fs-14 lh-16 fw-500">Google Play</span>
                        </span>
                    </a>
                    <a href="#" class="d-flex flex-row flex-nowrap justify-start align-center" @click.prevent.stop="showDev">
                        <img src="@/assets/img/landing/ico-windows.png" class="mr-3" />
                        <span>
                            <span class="fs-10 lh-22 fw-500">Доступно для</span><br /><span class="fs-14 lh-16 fw-500">Windows</span>
                        </span>
                    </a>
                    <a href="#" class="d-flex flex-row flex-nowrap justify-start align-center" @click.prevent.stop="showDev">
                        <img src="@/assets/img/landing/ico-linux.png" class="mr-3" />
                        <span>
                            <span class="fs-10 lh-22 fw-500">Доступно для</span><br /><span class="fs-14 lh-16 fw-500">Linux</span>
                        </span>
                    </a>
                </div>
            </section>
        </article>
        <footer class="black">
            <div class="container--landing d-flex flex-row flex-wrap justify-space-between align-center pt-12 pb-15">
                <div class="flex-grow-1">
                    <div class="fs-16 lh-20 fw-500 white--text">Разработано издательством “Экзамен” при поддержки ФИОКО.</div>
                    <div class="fs-11 lh-14 fw-500 white--text opacity-50">Разработанно на базе вариантов Всероссийских проверочных работ разработанных ФИОКО.</div>
                    <div class="fs-11 lh-14 fw-500 white--text opacity-50 mt-6">© 2021</div>
                </div>

                <div>
                    <img src="@/assets/img/landing/logo-fioko.png" alt="">
                </div>
            </div>
        </footer>

        <v-snackbar v-model="development">
            <p class="fw-700 fs-17 lh-14">Данный функционал пока недоступен.</p>
            <p>Приносим извинения за неудобства.</p>
            <template v-slot:action="{ attrs }">
                <v-btn
                    color="green darken-0"
                    v-bind="attrs"
                    class="ml-5"
                    @click="development = false"
                >
                    Закрыть
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>
<script>
import { mapState } from 'vuex'
import AssignedJobDemoCard from '@/components/landing/AssignedJobDemoCard.vue'
export default {
    components: { AssignedJobDemoCard },
    props: {
    	model: Object,
    	disabled: Boolean
    },
    data() {
        return {
            development: false,
            demo_carousel: 0
        }
    },  
    computed: {
        ...mapState('job', {
            demo: state => state.lists.demo.items
        }),
        demoChunked() {
            return _.chunk(this.demo, 3);
        },
        isDemoFetch() {
            return _.size(this.demo) > 0;
        }
    },
    methods: {
    	showDev() {
            this.development = true;
        }
    },
    async mounted() {
        if(!this.isDemoFetch)
            await this.$store.dispatch('job/fetchDemo');

        if (window.location.hash === this.$route.hash) {
            const el = document.getElementById(this.$route.hash.slice(1))
            if (el) {
                window.scrollTo(0, el.offsetTop)
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.container--landing{
    margin-left: auto;
    margin-right: auto;
    max-width: 44.625rem;
}
.nav-anchor{
    position: relative;
    top: -101px;
}
nav{
    height: 101px;
    overflow: hidden;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    padding-left: 256px;
    background: #FFF;

    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.12);
    a{
        color: #1F2238;
        margin-left: 4.5rem;
        padding: 2.5rem 0;
        &:first-child{
            margin-left: 0;
        }
        &:hover{
            color: #000;
        }
    }
}
article{
    margin-top: 101px!important;
}
section{
    font-size: 1rem;
    line-height: 1.375rem;

    &#head{
        img{
            margin-left: 50%;
            transform: translateX(-50%);
        }
    }
    
    &#about{
        img.list-icon{
            width: 24px;
            height: 24px;
            border-radius: 0;
        }
    }

    &#demo-section{
        .assigned-job-demo-card{
            flex: 0 0 14.1625rem;
            max-width: 14.1625rem;
        }
    }

    &#download{
        a{
            height: 3.5625rem;
            overflow: hidden;
            border: 0.5px solid rgba(0, 0, 0, 0.15);
            border-radius: 6px;
            padding: .66rem .9375rem;
            text-align: center;
            color: #000!important;

            > img{
                height: 100%;
                width: auto;
                flex: 0 0 auto;
            }
        }
    }
}
footer{
    img{
        flex: 0 0 5.375rem;
        width: 5.375rem;
        height: auto;
    }
}
</style>